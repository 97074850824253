import { lazy } from 'react';

/**
 * This file is used to map Monarch components to their respective components via custom component ID.
 * 
 * NOTE: Dynamic imports are not working with our skeletons with <MonarchSlot> due to Suspense overriding. 
 * Once this fix is implemented from RP team, dynamically import these components
 */

export const componentLookup: { [key: string]: React.ComponentType<any> } = {
    aaFAQ: lazy(() => import('~/components/aa-rv/AA-FAQ-Module')),
    aaFooter: lazy(() => import('~/components/aa-rv/AA-Footer')),
    aaTestimonials: lazy(() => import('~/components/aa-rv/AA-Testimonials')),
    aaEditableCTABanner1: lazy(() => import('~/components/aa-rv/AA-Editable-CTA-Banner-1')),
    aaEditableNavbar: lazy(() => import('~/components/aa-rv/AA-Editable-Navbar')),
    empty: lazy(() => import('~/components/editable/Empty')),
    aaEditableAssistBanner: lazy(() => import('~/components/aa-rv/AA-Yellow-Banner')),
    aaEditableHero: lazy(() => import('~/components/aa-rv/AA-Editable-Hero')),
    aaEditableSavings: lazy(() => import('~/components/aa-rv/AA-Editable-Savings')),
    aaEditableStickyMobileCTA: lazy(() => import('~/components/aa-rv/AA-Editable-Sticky-Mobile-CTA')),
    aaMemberRewards: lazy(() => import('~/components/aa-rv/AA-Member-Rewards')),
    aaBenefits: lazy(() => import('~/components/aa-rv/AA-Benefits')),
    editableTestimonials: lazy(() => import('~/components/Layout/Testimonials')),
    editableBenefitCardsCarousel: lazy(() => import('~/components/Layout/Features')),
    editableCompareTable: lazy(() => import('~/components/Layout/CompareCards')),
    editableMemberRewards: lazy(() => import('~/components/Layout/MemberRewards')),
    editableCTABanner: lazy(() => import('~/components/Layout/CTABanner')),
    editableClassicHero: lazy(() => import('~/components/Layout/Hero')),
    editableBenefitsTileCarousel: lazy(() => import('~/components/editable/BenefitsTileCarousel')),
    editableSunriseBanner: lazy(() => import('~/components/Layout/SunriseBanner')),
    editableCTAPromotionBanner: lazy(() => import('~/components/Layout/PromotionCTABanner')),
    editableArcHero: lazy(() => import('~/components/editable/ArcHero')),
    editableSubNavbarCountdownBanner: lazy(() => import('~/components/editable/SubNavbarCountdownBanner')),
    editableMembershipPricingModule: lazy(() => import('~/components/editable/MembershipPricingModule')),
    editableBoxedPromotionBanner: lazy(() => import('~/components/editable/BoxedPromotionBanner')),
    editableMemberRewardsFilter: lazy(() => import('~/components/editable/MemberRewardsFilter')),
    editableSimplifiedSolidHero: lazy(() => import('~/components/editable/SimplifiedSolidHero')),
    editableSlimmedNavbar: lazy(() => import('~/components/editable/SlimmedNavbar')),
    editableHoveringStickyMobileCTA: lazy(() => import('~/components/editable/HoveringStickyMobileCTA')),
    editableLifestyleCarousel: lazy(() => import('~/components/editable/LifestyleCarousel')),
    editableSolidIconHero: lazy(() => import('~/components/editable/SolidIconHero')),
    editableBenefitsTileHero: lazy(() => import('~/components/editable/BenefitsTileHero')),
    editableSimpleTabbedCarouselHero: lazy(() => import('~/components/editable/SimpleTabbedCarouselHero')),
    editableSavingsQuiz: lazy(() => import('~/components/editable/SavingsCalculatorQuiz')),
    editableSavingsCalculator: lazy(() => import('~/components/editable/SavingsCalculator')),
    editableSignUpBanner: lazy(() => import('~/components/Layout/SignUpBanner')),
    editableScrollingStaticHero: lazy(() => import('~/components/editable/ScrollingStaticHero')),
    editableCarouselHero: lazy(() => import('~/components/editable/CarouselHero')),
    editableBenefitHighlightModule: lazy(() => import('~/components/editable/BenefitHighlightModule')),
    editableLiveBetterHero: lazy(() => import('~/components/editable/LiveBetterHero')),
    editableAssistFullBanner: lazy(() => import('~/components/editable/AssistFullBanner')),
    editableAssistSubNavBanner: lazy(() => import('~/components/editable/AssistSubNavBanner')),
    editableLifestyleTestimonials: lazy(() => import('~/components/editable/LifestyleTestimonials')),
    editableEducationalBanner: lazy(() => import('~/components/editable/EducationalBanner')),
    faq: lazy(() => import('~/components/Layout/FAQ')),
    footer: lazy(() => import('~/components/Layout/Footer')),
    editableNavbar: lazy(() => import('~/components/Layout/Header')),
    editableSubNavScrollingBanner: lazy(() => import('~/components/editable/SubNavScrollingBanner')),
    editableTestimonialsHero: lazy(() => import('~/components/editable/TestimonialsHero')),
    editableImageOverlayCenterHero: lazy(() => import('~/components/editable/ImageOverlayCenterHero')),
    editableEntryModal: lazy(() => import('~/components/editable/EntryModal')),
    editableImageOverlayHero: lazy(() => import('~/components/editable/ImageOverlayHero')),
    editableSunriseCarouselHero: lazy(() => import('~/components/editable/SunriseCarouselHero')),
    editableScrollingCarouselHero: lazy(() => import('~/components/editable/ScrollingCarouselHero')),
    editableClassicMobileHero: lazy(() => import('~/components/editable/ClassicMobileHero')),
};
